import React from 'react';
import Footer from './Footer';
import Header from './Header';

function LandingPage() {
  return (
    <>
     
    <main className="main" id="top">
      <Header/>

      <section className="py-0 bg-light-gradient">
        <div
          className="bg-holder"
          style={{ backgroundImage: "url('assets/img/illustrations/hero-bg.png')", backgroundPosition: 'top right', backgroundSize: 'contain' }}
        ></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5 order-md-1 pt-8">
              <img className="img-fluid" src="assets/img/gallery/hero.png" alt="" />
            </div>
            <div className="col-md-7 col-lg-6 text-center text-md-start pt-5 pt-md-9">
              <h1 className="display-2 fw-bold fs-4 fs-md-5 fs-xl-6">
                Building exactly the <br />
                Dental Solution You Need.
              </h1>
              <p className="mt-3 mb-4">
              Denteex is your one-stop digital platform for clinic management, from technology support to marketing.
              </p>
              <a className="btn btn-lg btn-info rounded-pill me-2" href="#" role="button">
              Start Your Journey with Denteex
              </a>
              <span> or </span>
              <a className="btn btn-link ps-1 ps-md-4 ps-lg-1" href="#" role="button">
              Discover Our  &amp; Solutions ›
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row flex-center">
            <div className="col-auto text-center my-4">
              <h1 className="display-3 fw-bold">
              Your Dental Practice, 
                <br />
                <span>Made Simplified</span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-5 mb-md-0">
              <img className="img-fluid shadow-sm" src="assets/img/gallery/feature-1.png" alt="" />
              <div className="mt-3 text-center text-md-start">
                <h4 className="display-6 fs-2 fs-lg-3 fw-bold">Technology Support</h4>
                <p className="mb-0">Get expert support to ensure your clinic’s tech runs smoothly</p>
                <a className="btn btn-link ps-0" href="#" role="button">
                  Ecommerce Wordpress ›
                </a>
              </div>
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <img className="img-fluid shadow-sm" src="assets/img/gallery/feature-2.png" alt="" />
              <div className="mt-3 text-center text-md-start">
                <h4 className="display-6 fs-2 fs-lg-3 fw-bold">Patient & Appointment Management</h4>
                <p className="mb-0">Optimize patient care with efficient scheduling and record-keeping.</p>
                <a className="btn btn-link ps-0" href="#" role="button">
                  Browse Extensions ›
                </a>
              </div>
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <img className="img-fluid shadow-sm" src="assets/img/gallery/feature-3.png" alt="" />
              <div className="mt-3 text-center text-md-start">
                <h4 className="display-6 fs-2 fs-lg-3 fw-bold">Website & Marketing</h4>
                <p className="mb-0">Grow your clinic’s reach with custom website and marketing services.</p>
                <a className="btn btn-link ps-0" href="#" role="button">
                  Check our Forums ›
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="shapeup">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
          <path fill="#7854F7" fillOpacity="1" d="M0,64L120,69.3C240,75,480,85,720,85.3C960,85,1200,75,1320,69.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
      </div>

      <section className="pt-4 pt-md-5 bg-info">
        <div
          className="bg-holder"
          style={{
            backgroundImage: "url('assets/img/illustrations/about-bg.png')",
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
          }}
        ></div>
        <div className="container">
          <div className="row mb-5 text-center">
            <div className="col-12 col-md-4 mb-4">
              <img className="img-fluid rounded-3 shadow-lg image-up" src="assets/img/gallery/about-2.png" alt="" />
            </div>
            <div className="col-12 col-md-8 text-md-start" style={{ maxWidth: '460px' }}>
              <h3 className="fw-medium text-light">Develop Your Dental Practice <br /> Without Limits</h3>
              <p className="text-light">
              Denteex provides scalable solutions, empowering clinics to reach their full potential with robust resources and community support.
              </p>
              <a className="btn btn-lg btn-success rounded-pill" href="#">
                Read the Documentation
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-7 bg-light-gradient">
        <div
          className="bg-holder"
          style={{
            backgroundImage: "url('assets/img/gallery/testimonial-bg.png')",
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
          }}
        ></div>
        <div className="container">
          <div className="row flex-center mb-5">
            <div className="col-auto text-center">
              <h1 className="display-3 fw-bold fs-4 fs-md-6">
                Trusted by Dentiest <br />
                <span>&amp; Dental Clinics</span>
              </h1>
            </div>
          </div>
          <div className="row flex-center">
            <div className="col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
              <div className="carousel slide carousel-fade" id="carouselExampleControls" data-bs-ride="carousel">
                <div className="carousel-inner overflow-visible">
                  <div className="carousel-item z-index-1 active">
                    <div className="card py-4 px-3 py-sm-6 px-sm-5 px-xl-7">
                      <div className="bg-holder mt-4" style={{ backgroundImage: "url('assets/img/gallery/q.png')", backgroundPosition: 'center top', backgroundSize: '150px' }}></div>
                      <div className="card-body z-index-1">
                        <p className="card-text fs-2 text-center">
                        More clinics choose Denteex for our commitment to reliable, easy-to-use solutions. Our focus is on making your clinic run smoother so you can focus on patient care.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Additional carousel items */}
                </div>
                <div className="row mt-7 pe-6">
                  <div className="col-12 position-relative text-end">
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-100 pb-0">
        <div className="container">
          <div className="row flex-center">
            <div className="col-xl-5 text-center mb-5 z-index-1">
              <h1 className="display-3 fw-bold fs-4 fs-md-6">Supported growing dental Clinics</h1>
              <p>Our team is dedicated to supporting your clinic. From onboarding to ongoing assistance, Denteex’s customer success team is here to help your practice thrive.</p>
            </div>
          </div>
        </div>
        <div className="position-relative text-center">
          <div className="bg-holder" style={{ backgroundImage: "url(assets/img/gallery/people-bg-shape.png), url(assets/img/gallery/people-bg-dot.png)" }}></div>
          <img className="img-fluid position-relative z-index-1" src="assets/img/gallery/people.png" alt="" />
        </div>
        
      </section>

      <Footer/>
    </main>
 
    </>
  );
}

export default LandingPage;
