import React from 'react'

const Footer = () => {
  return (
    <>
     <section className="py-0">
      <div
        className="bg-holder z-index-2"
        style={{
          backgroundImage: "url('assets/img/illustrations/cta-bg.png')",
          backgroundPosition: "bottom right",
          backgroundSize: "61px 60px",
          marginTop: "15px",
          marginRight: "15px",
          marginLeft: "-58px",
        }}
      ></div>
      {/* /.bg-holder */}

      <div className="container-fluid px-0">
        <div className="card py-4 border-0 rounded-0 bg-primary">
          <div className="card-body">
            <div className="row flex-center">
              <div className="col-xl-9 d-flex justify-content-center mb-3 mb-xl-0">
                <h2 className="text-light fw-bold">
                Denteex - Collaborate for a Million Smiles
                  <br />
                  Join hundreds of clinics using Denteex to deliver exceptional patient care.
                </h2>
              </div>
              <div className="col-xl-3 text-center">
                <a className="btn btn-lg btn-outline-light rounded-pill" href="#">
                GET STARTED WITH DENTEEX
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <section className="bg-info pt-0 pb-0">
        
      <div className="container">
        <div className="row justify-content-sm-between py-6">
          <div className="col-auto mb-2">
            <div className="d-flex">
              <svg className="bi bi-check-circle" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
              <p className="mb-0 text-light ms-2">Free Staff Training and Development</p>
            </div>
          </div>
          <div className="col-auto mb-2">
            <div className="d-flex">
              <svg className="bi bi-gear-wide-connected" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" viewBox="0 0 16 16">
                <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646l.087.065-.087-.065z" />
              </svg>
              <p className="mb-0 text-light ms-2">24 * 7 Support across the world</p>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex">
              <svg className="bi bi-shield-lock-fill" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#ffffff" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z" />
              </svg>
              <p className="mb-0 text-light ms-2">Safe &amp; Secure online payment</p>
            </div>
          </div>
        </div>
        <div className="row flex-center">
          <div className="col-auto py-4">
            <a href="#"><img className="img-fluid" src="assets/img/w-denteex-logo.png" alt="" width="300"/></a>
          </div>
        </div>
        <hr className="opacity-25" />
        <div className="row justify-content-lg-around">
          <div className="col-6 col-sm-4 col-lg-auto mb-3 order-0">
            <h6 className="text-light lh-lg text-uppercase">Who we Are</h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">About</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Team</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Work With Us</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3 order-4 order-lg-1">
            <h6 className="text-light lh-lg text-uppercase">Denteex</h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Features</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Payments</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Marketing</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Shipping</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Extension</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Store</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Development blog</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Community</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Email Newsletter</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3 order-1 order-lg-2">
            <h6 className="text-light lh-lg text-uppercase">Products</h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Storefront</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">WooSlider</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Sensei</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Sensei Extensions</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3 order-3 order-lg-3">
            <h6 className="text-light lh-lg text-uppercase">Support</h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Documentation</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Customizations</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Support Policy</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Contact</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Privacy Notice for</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">California Users</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3 order-2 order-lg-4">
            <h6 className="text-light lh-lg text-uppercase">We recommend</h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Onboarding</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Hosting Solutions</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Pre-sales FAQ</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Success Stories</a></li>
              <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="#!">Design Feedback Group</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

      <section className="py-2">
      <div className="container">
        <div className="row flex-center">
          <div className="col-sm-12 col-md-3 text-md-start px-md-0 pt-md-2 text-center">
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item mr-3">
                <a className="text-decoration-none" href="#!">
                  <svg className="bi bi-twitter" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#272D4E" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a className="text-decoration-none" href="#!">
                  <svg className="bi bi-facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#272D4E" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a className="text-decoration-none" href="#!">
                  <svg className="bi bi-rss" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#272D4E" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-3-8.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a className="text-decoration-none" href="#!">
                  <svg className="bi bi-instagram" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#272D4E" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3 px-md-0 text-md-center order-1 order-md-0">
            <div className="text-400 text-center">
              {/* <p className="text-center text-md-end text-xl-start">All Rights Reserved</p> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6  text-md-end">
            
            <p className="text-center text-md-end ">All Rights Reserved by <a href="www.kodekalp.com">KodeKalp Global Technologies</a></p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Footer


